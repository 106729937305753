import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { IProduct } from '../../types';
import { normalizeNameForUrl } from '../../helpers';
interface ProductDataSectionProps {
  storeId: string;
  harvestId: string;
  product: IProduct;
  soldCountText?: string;
}

export const ProductDataSection = ({
  storeId,
  harvestId,
  product,
  soldCountText,
}: ProductDataSectionProps) => {
  const { id, Name, Price, RegularPrice, Brand, URLPromoCode } =
    product;

  const price = Price !== 0 ? Price : RegularPrice;
  const brand = Brand ? Brand : '';
  let promoCode: string;
  if (URLPromoCode != null) {
    promoCode = '__promoCode-' + URLPromoCode;
  } else {
    promoCode = '__promoCode-';
  }
  const navigate = useNavigate();
  const navigateToProduct = (id: string) =>
    navigate(`/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
      Name
    )}_${price}_${promoCode}`);
  return (
    <div>
      <ProductName onClick={() => navigateToProduct(product.id)}>
        {product.Name}
      </ProductName>
      <SoldCount onClick={() => navigateToProduct(product.id)}>
        {"10k+ " + (soldCountText || "bought past month")}
      </SoldCount>
    </div>
  );
};

const ProductName = styled.p`
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  cursor: pointer;
`;

const SoldCount = styled.p`
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;
