import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import { IProduct } from '../../types';
// import {
//   BrandContainer,
//   Container,
//   DescriptionContainer,
//   Header,
//   Paragraph,
//   ProductContainer,
//   ProductHeader,
// } from './styles';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { IAddProductButtonFunctionality, TCurrency } from '../../types/config';
import { normalizeNameForUrl } from '../../helpers';
import { AddToCartButton } from '../AddToCartButton';
import styled from '@emotion/styled';
import { theme } from '../../theme';

export const AdvBanner = ({
  TermBannerImage,
  TermBannerProducts,
  TermBannerSponsoredBy,
  TermBannerText,
  Currency,
  TermBannerVideo,
  TermBannerVideoPosition,
}: {
  TermBannerImage: { fullpath: string };
  TermBannerProducts: IProduct[];
  TermBannerSponsoredBy: string;
  TermBannerText: string;
  Currency: TCurrency;
  TermBannerVideo?: string;
  TermBannerVideoPosition?: string;
}) => {
  const { storeId = '', categoryId, harvestId = '' } = useParams();
  const navigate = useNavigate();
  const isVideoOnTop = TermBannerVideoPosition === 'top';

  const navigateToProduct = (url: string) => {
    // navigate(`/${storeId}/${harvestId}/ips/${id}__`);
    navigate(url);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };
  const currency = Currency ? Currency : '$';

  return (<>
    {TermBannerVideo && isVideoOnTop &&
      <BannerVideo className={'promoBannerVideo'}
      src={`${imgSrcByEnv()}${TermBannerVideo}`} controls autoPlay muted loop playsInline />
    }
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid lightgrey',
        paddingBottom: '8px',
        width:'100%',
      }}
    >
      <StyledSponsorName>
        Sponsored by {TermBannerSponsoredBy}
      </StyledSponsorName>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div>
          <img
            src={`${imgSrcByEnv()}${TermBannerImage?.fullpath}`}
            alt={TermBannerSponsoredBy}
            style={{
              width: '100px',
              height: '150px',
              objectFit: 'contain',
              background:
                'linear-gradient(to right, rgba(145,222,229,0.3), white 90%)',
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'auto',
          }}
        >
          {TermBannerProducts.map((product) => {
            const {
              id,
              Name,
              Price,
              RegularPrice,
              Brand,
              URLPromoCode,
              RROverall,
            } = product;
            const price = Price !== 0 ? Price : RegularPrice;
            const brand = Brand ? Brand : '';
            let promoCode;
            if (URLPromoCode != null) {
              promoCode = '__promoCode-' + URLPromoCode;
            } else {
              promoCode = '__promoCode-';
            }
            const productRoute = categoryId
              ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                  product?.id
                }__${brand}__${normalizeNameForUrl(product?.Name)}}_${price}`
              : `/${storeId}/${harvestId}/ips/${
                  product?.id
                }__${brand}__${normalizeNameForUrl(product?.Name)}_${price}`;

            const url = categoryId
              ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                  Name
                )}_${price}_${promoCode}`
              : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                  Name
                )}_${price}_${promoCode}`;

            return (
              <div
                style={{
                  display: 'flex',
                  paddingRight: '8px',
                  width: '100%',
                  height: '100%',
                  marginRight: '8px',
                  borderRight: '1px solid #f5f5f5',
                }}
                onClick={() => navigateToProduct(url)}
              >
                <img
                  src={`${imgSrcByEnv()}${
                    product?.Gallery[0]?.image?.fullpath
                  }`}
                  alt="banner"
                  style={{
                    objectFit: 'fill',
                    height: '150px',
                    width: '130px',
                    padding: '16px',
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '4px 8px 4px 4px',
                    width: '140px',
                  }}
                >
                  <p
                    style={{ width: '100%', fontSize: '12px', fontWeight: 600 }}
                  >
                    {product.Name.slice(0, 50)}
                  </p>
                  <PriceContainer>
                    {product.Price && product.Price !== 0 ? (
                      <p className="price">
                        Price: {currency}
                        {price}
                        <span className="oldPrice">
                          {' '}
                          (<s>${product.RegularPrice.toFixed(2)}</s>)
                        </span>
                      </p>
                    ) : (
                      <p className="price">
                        <sup>{currency}</sup>
                        {price}
                      </p>
                    )}
                    {/* <span className="pricePerUnit">{pricePerUnit}</span> */}
                  </PriceContainer>

                  {product.RROverall && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <p style={{ paddingRight: '4px' }}>
                        Rate: {product.RROverall}
                      </p>
                      <Stack spacing={1}>
                        <Rating
                          value={product.RROverall}
                          precision={0.5}
                          readOnly
                        />
                      </Stack>
                    </div>
                  )}

                  <AddToCartButton
                    type={IAddProductButtonFunctionality.text}
                    product={product}
                    handleButtonClick={handleButtonClick}
                    url={url}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    {TermBannerVideo && !isVideoOnTop &&
          <BannerVideo className={'promoBannerVideo'} 
          src={`${imgSrcByEnv()}${TermBannerVideo}`} controls autoPlay muted loop playsInline />
    }
  </>);
};

const StyledSponsorName = styled.div`
  display: flex;
  width: 100%;
  font-size: 10px;
  font-weight: 600;
  padding: 8px 8px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 8px;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 5px;
  line-height: 17px;

  .price {
    font-size: 12px;
    .oldPrice {
      font-size: 10px;
      color: gray;
    }
  }

  .pricePerUnit {
    margin-left: 2px;
    font-size: 12px;
    color: ${theme.amazon.greyDark};
  }
  .simpleProduct {
    font-size: 22px;
    color: ${theme.amazon.red};
  }
`;

const BannerVideo = styled.video`
display:block;
width:100%;
margin: 0 auto;
`