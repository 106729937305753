import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { IProduct } from '../../types';
import { normalizeNameForUrl } from '../../helpers';
interface CheckoutSectionProps {
  imgSrcByEnv: () => void;
  storeId: string;
  harvestId: string;
  product: IProduct;
}

export const ProductGalleryImage = ({
  imgSrcByEnv,
  product,
  storeId,
  harvestId,
}: CheckoutSectionProps) => {
  const { id, Name, Price, RegularPrice, Brand, URLPromoCode } =
    product;

  const price = Price !== 0 ? Price : RegularPrice;
  const brand = Brand ? Brand : '';
  let promoCode: string;
  if (URLPromoCode != null) {
    promoCode = '__promoCode-' + URLPromoCode;
  } else {
    promoCode = '__promoCode-';
  }
  const navigate = useNavigate();

  const navigateToProduct = (id: string) =>
    navigate(`/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
      Name
    )}_${price}_${promoCode}`);

  return (
    <ImageContainer>
      <ProductImage
        src={`${imgSrcByEnv()}${product.Gallery[0]?.image?.fullpath}`}
        alt="product logo"
        onClick={() => navigateToProduct(product.id)}
      />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  position: relative;
  flex: 1 1 50%;
`;

const ProductImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
`;
